import {
	Divider,
	Link as ChakraLink,
	useMultiStyleConfig,
	HStack,
} from "@chakra-ui/react";
import React from "react";
import { NextLink } from "src/components/NextLink";
import { ArrowRight, ChevronUp } from "src/icons";
import { replacePipeWithShy } from "src/lib/replaceStringWithReactNode";
import { createBreadcrumbs, truthy } from "src/lib/utils";
import { HeaderDropdownFragment } from "../../__generated__/cms-schema.codegen";
import { Family, FamilySubMenu } from "../FamilySubMenu/FamilySubMenu";
import { addDividerToHeaderMenuItems } from "../Header/CMSMenuItems";
import { useMobileMenuContext } from "../Header/MobileMenu/MobileMenu";
import {
	HeaderAccordion,
	HeaderAccordionButton,
	HeaderAccordionContent,
} from "../HeaderAccordion/HeaderAccordion";
import {
	HeaderDropdown,
	HeaderDropdownButton,
	HeaderDropdownContent,
	HeaderDropdownItem,
	MenuArrow,
} from "../HeaderDropdown/HeaderDropdown";

type HeaderSubmenuProps = {
	label: string;
	items: HeaderDropdownFragment["dropdownItems"];
	id: string;
};

const getHeaderDropdownItemDetails = (
	item: HeaderDropdownFragment["dropdownItems"][number],
) => {
	if (item.__typename === "InsuranceProductFamilyRecord") {
		return {
			name: item.name,
			href: `/${item.slug}`,
		};
	}

	if (item.__typename === "BreadcrumbRecord") {
		return {
			name: item.name,
			href: `/${createBreadcrumbs(item)
				.map(({ slug }) => slug)
				.join("/")}`,
		};
	}

	return {
		name: item.name,
		href: item.slug,
	};
};

export const HeaderSubMenu: React.FC<HeaderSubmenuProps> = ({
	label,
	items,
	id,
}) => {
	const mobileMenuContext = useMobileMenuContext();
	const styles = useMultiStyleConfig("HeaderDropdown");

	return (
		<>
			<HeaderAccordion
				display={{
					base: "block",
					desktopNavigation: "none",
				}}
			>
				<HeaderAccordionButton id={id}>{label}</HeaderAccordionButton>
				<HeaderAccordionContent
					sx={{
						"hr + hr": {
							display: "none",
						},
					}}
				>
					{items
						.map((item) => {
							if (
								item.__typename ===
								"InsuranceProductFamilyRecord"
							) {
								return (
									<FamilySubMenu
										key={item.id}
										family={item}
										isMobile
									/>
								);
							}

							const { href } = getHeaderDropdownItemDetails(item);

							return (
								<NextLink key={item.name} href={href} passHref>
									<ChakraLink
										id={href}
										onClick={() =>
											mobileMenuContext?.onClose()
										}
										variant="headerDropdown"
									>
										{item.name}
									</ChakraLink>
								</NextLink>
							);
						})
						.filter(truthy)
						.flatMap(
							addDividerToHeaderMenuItems(
								<Divider
									display={{
										base: "block",
										desktopNavigation: "none",
									}}
								/>,
							),
						)}
				</HeaderAccordionContent>
			</HeaderAccordion>

			<HeaderDropdown>
				<HeaderDropdownButton
					display={{
						base: "none",
						desktopNavigation: "block",
					}}
					id={id}
					variant="headerDropdown"
				>
					{label}
					<ChevronUp />
				</HeaderDropdownButton>

				<HeaderDropdownContent
					display={{
						base: "none",
						desktopNavigation: "block",
					}}
				>
					<MenuArrow />
					<HStack gap={2} alignItems="start">
						{items
							.filter(
								(item): item is Family =>
									item.__typename ===
									"InsuranceProductFamilyRecord",
							)
							.map((family) => {
								return (
									<FamilySubMenu
										key={family.id}
										family={family}
									/>
								);
							})}
					</HStack>
					{items
						.filter(
							(item): item is Exclude<typeof item, Family> =>
								item.__typename !==
								"InsuranceProductFamilyRecord",
						)
						.map((item) => {
							const { href } = getHeaderDropdownItemDetails(item);

							return (
								<HeaderDropdownItem
									key={item.name}
									href={href}
									label={replacePipeWithShy(item.name)}
									icon={<ArrowRight sx={styles.arrow} />}
								/>
							);
						})}
				</HeaderDropdownContent>
			</HeaderDropdown>
		</>
	);
};

// 🔬 TBD: Please evaluate
