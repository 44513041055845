import { Divider } from "@chakra-ui/react";
import React, { ReactNode, useMemo } from "react";
import ReactIs from "react-is";
import {
	InlineLinkButtonFragment,
	InlineModalButtonFragment,
} from "src/__generated__/cms-schema.codegen";
import { useFeatureFlag } from "src/lib/featureFlags";
import { useLoginInfo } from "src/queries/emil/account";
import { HeaderProps } from "./Header";
import { useMobileMenuContext } from "./MobileMenu/MobileMenu";
import { CMSButton } from "../CMSButton/CMSButton";
import { CMSLinkButtonStyle } from "../CMSButton/CMSLinkButton";
import { CmsMenuItem } from "../CmsMenuItem/CmsMenuItem";
import { HeaderSubMenu } from "../HeaderSubMenu/HeaderSubMenu";
import { HeaderSubMenuNew } from "../HeaderSubMenu/HeaderSubMenuNew";

type CMSMenuItemsProps = {
	items: HeaderProps["links"];
	children: ReactNode;
};

export const addDividerToHeaderMenuItems =
	(divider: ReactNode) =>
	(item: ReactNode, i: number, arr: Array<ReactNode>) => [
		item,
		i !== arr.length - 1 && (
			<React.Fragment
				// we need a "real" key here, there's no better way unfortunately
				key={`divider-${ReactIs.isElement(item) ? item.key : i}`}
			>
				{divider}
			</React.Fragment>
		),
	];

export const CMSMenuItems: React.FC<CMSMenuItemsProps> = ({
	items,
	children,
}) => {
	const mobileMenuContext = useMobileMenuContext();
	const loginInfo = useLoginInfo();

	const showRefactoredMenu = useFeatureFlag("refactored-menu");

	const filteredItems = useMemo(() => {
		return items?.filter((item) => {
			if (
				item.__typename === "InlineModalButtonRecord" &&
				item.modalName === "Login"
			) {
				if (loginInfo?.email) {
					return false;
				}
			}

			return true;
		});
	}, [items, loginInfo?.email]);

	const sortedItems = useMemo(() => {
		const SORT_ORDER: {
			text: NonNullable<CMSMenuItemsProps["items"]>;
			outline: Array<
				InlineLinkButtonFragment | InlineModalButtonFragment
			>;
			solid: Array<InlineLinkButtonFragment | InlineModalButtonFragment>;
		} = {
			text: [],
			outline: [],
			solid: [],
		};

		// NOTE: lets sort the items so they appear in a meaningful order e.g. first text links, then outline buttons, then solid buttons
		return (
			filteredItems?.reduce((prev, curr) => {
				if (
					curr.__typename === "InlineLinkButtonRecord" ||
					curr.__typename === "InlineModalButtonRecord"
				) {
					prev[curr.linkStyle as CMSLinkButtonStyle].push(curr);
				} else {
					prev.text.push(curr);
				}

				return prev;
			}, SORT_ORDER) ?? SORT_ORDER
		);
	}, [filteredItems]);

	//  If a page has a target group with a defined header, then we use it even if there are no links for cases where this is intentional
	return (
		<>
			{sortedItems.text
				.map((item) => {
					// TODO: Rename this record in Dato to HeaderSubMenu (not always a dropdown)
					if (item.__typename === "HeaderDropdownRecord") {
						if (showRefactoredMenu) {
							return (
								<HeaderSubMenuNew
									key={item.analyticsId}
									label={item.label}
									items={item.dropdownItemsNew}
									id={item.analyticsId}
								/>
							);
						}

						return (
							<HeaderSubMenu
								key={item.analyticsId}
								label={item.label}
								items={item.dropdownItems}
								id={item.analyticsId}
							/>
						);
					}

					return (
						<CmsMenuItem
							key={item.id}
							onClick={mobileMenuContext?.onClose}
						>
							<CMSButton {...item} inHeader />
						</CmsMenuItem>
					);
				})
				// NOTE: add divider except for the last element
				.flatMap(
					addDividerToHeaderMenuItems(
						<Divider display={{ base: "block", lg: "none" }} />,
					),
				)}

			{[...sortedItems.outline, ...sortedItems.solid].map((item) => {
				return (
					<CmsMenuItem
						key={item.id}
						onClick={mobileMenuContext?.onClose}
					>
						<CMSButton {...item} inHeader />
					</CmsMenuItem>
				);
			})}
			{!items && children}
		</>
	);
};

// 🔬 TBD: Please evaluate
