import {
	Divider,
	Link as ChakraLink,
	useMultiStyleConfig,
	chakra,
	ChakraProps,
} from "@chakra-ui/react";
import React from "react";
import { ProductHeaderItemFragment } from "src/__generated__/client.codegen";
import { NextLink } from "src/components/NextLink";
import { useTranslatedString } from "src/i18n/i18n";
import { ArrowRight, ChevronUp } from "src/icons";
import { replacePipeWithShy } from "src/lib/replaceStringWithReactNode";
import { hexColorWithOpacity, truthy } from "src/lib/utils";
import { z } from "zod";
import { HeaderDropdownFragment } from "../../__generated__/cms-schema.codegen";
import { addDividerToHeaderMenuItems } from "../Header/CMSMenuItems";
import {
	HeaderAccordion,
	HeaderAccordionButton as ChakraHeaderAccordionButton,
	HeaderAccordionContent,
} from "../HeaderAccordion/HeaderAccordion";
import {
	HeaderDropdown,
	HeaderDropdownButton,
	HeaderDropdownContent,
	HeaderDropdownItem,
	MenuArrow,
} from "../HeaderDropdown/HeaderDropdown";

type HeaderSubmenuProps = {
	label: string;
	items: HeaderDropdownFragment["dropdownItemsNew"];
	id: string;
};

const HeaderAccordionButton: React.FC<{ name: string } & ChakraProps> = ({
	name,
	...rest
}) => {
	const styles = useMultiStyleConfig("HeaderDropdown");

	return (
		<ChakraHeaderAccordionButton
			sx={styles.accordionButton}
			style={{
				background: "none",
				color: "white",
				textDecoration: "none",
			}}
			{...rest}
		>
			{replacePipeWithShy(name)}
		</ChakraHeaderAccordionButton>
	);
};

const ProductHeaderItem: React.FC<{
	products: Array<ProductHeaderItemFragment>;
	familySlug: string;
	isMobile?: boolean;
}> = ({ familySlug, products, isMobile }) => {
	const styles = useMultiStyleConfig("HeaderDropdown");
	const t = useTranslatedString();

	if (isMobile) {
		return products
			.map(({ id, label: productLabel, link: product, targetGroups }) => {
				// the product has multiple target groups, make the product an accordion button and map the target groups beneath
				if (targetGroups.length > 1) {
					return (
						<HeaderAccordion key={id}>
							{/* Product accordion button */}
							<HeaderAccordionButton
								name={
									productLabel ? productLabel : product.name
								}
							/>
							<HeaderAccordionContent
								sx={{
									"hr + hr": {
										display: "none",
									},
								}}
							>
								<Divider />
								{/* Product overview link */}
								<NextLink
									passHref
									href={
										product.__typename ===
										"InsuranceProductRecord"
											? `/${familySlug}/${product.slug}`
											: `/${product.slug}`
									}
								>
									<ChakraLink
										id={product.id}
										textDecoration="none"
										fontWeight={600}
										paddingLeft={24}
									>
										{t("headerSubMenu.overview")}{" "}
										{productLabel
											? productLabel
											: replacePipeWithShy(product.name)}
									</ChakraLink>
								</NextLink>
								<Divider />
								{targetGroups
									.filter(truthy)
									.map(({ id, label, link: targetGroup }) => {
										const { __typename, slug } =
											targetGroup;

										return (
											<NextLink
												key={id}
												passHref
												href={
													__typename ===
													"TargetGroupRecord"
														? `/${familySlug}/${product.slug}/${slug}`
														: `/${slug}`
												}
											>
												<ChakraLink
													textDecoration="none"
													fontWeight={500}
													paddingLeft={24}
													id={id}
												>
													{label
														? label
														: targetGroup.name}
												</ChakraLink>
											</NextLink>
										);
									})
									.flatMap(
										addDividerToHeaderMenuItems(
											<Divider />,
										),
									)}
							</HeaderAccordionContent>
						</HeaderAccordion>
					);
				}

				const parsed = z
					.object({
						currentVersion: z.object({
							externalLink: z.string().url().min(1),
						}),
					})
					.safeParse(product);

				return (
					<chakra.div key={id}>
						{/* This is true if the product has an external link, i.e. there's no according product in Eis */}
						{parsed.success ? (
							<ChakraLink
								id={product.id}
								textDecoration="none"
								fontWeight={600}
								paddingLeft={8}
								href={parsed.data.currentVersion.externalLink}
								isExternal
							>
								{replacePipeWithShy(product.name)}
							</ChakraLink>
						) : (
							<NextLink
								passHref
								href={
									product.__typename ===
									"InsuranceProductRecord"
										? `/${familySlug}/${product.slug}`
										: `/${product.slug}`
								}
							>
								<ChakraLink
									id={id}
									textDecoration="none"
									fontWeight={600}
									paddingLeft={8}
								>
									{productLabel
										? productLabel
										: replacePipeWithShy(product.name)}
								</ChakraLink>
							</NextLink>
						)}
					</chakra.div>
				);
			})
			.flatMap(addDividerToHeaderMenuItems(<Divider />));
	}

	return products.map(
		({ id, label: productLabel, link: product, targetGroups }) => {
			return (
				<chakra.div pt="4" key={id}>
					<HeaderDropdownItem
						href={
							product.__typename === "InsuranceProductRecord"
								? `${familySlug}/${product.slug}`
								: product.slug
						}
						label={
							productLabel
								? productLabel
								: replacePipeWithShy(product.name)
						}
						icon={<ArrowRight sx={styles.arrow} />}
						fontWeight="bold"
						fontSize="md"
					/>
					{targetGroups.map(({ id, label, link: targetGroup }) => {
						const { __typename, slug } = targetGroup;

						return (
							<HeaderDropdownItem
								key={id}
								href={
									__typename === "TargetGroupRecord"
										? `${familySlug}/${product.slug}/${slug}`
										: slug
								}
								label={label ? label : targetGroup.name}
								icon={<ArrowRight sx={styles.arrow} />}
								fontSize="sm"
								fontWeight={500}
							/>
						);
					})}
				</chakra.div>
			);
		},
	);
};

const HeaderDropdownItems: React.FC<{
	items: HeaderDropdownFragment["dropdownItemsNew"];
	isMobile?: boolean;
}> = ({ items, isMobile }) => {
	const styles = useMultiStyleConfig("HeaderDropdown");
	const t = useTranslatedString();

	const familyHeaderItems = items.flatMap((item) => {
		return item.__typename === "FamilyHeaderItemRecord" ? item : [];
	});

	const headerItems = items.flatMap((item) => {
		return item.__typename === "HeaderItemRecord" ? item : [];
	});

	if (isMobile) {
		return familyHeaderItems
			.map(({ family: { slug: familySlug, name, id }, products }) => (
				<HeaderAccordion key={id}>
					<HeaderAccordionButton
						textTransform="uppercase"
						name={name}
					/>
					<HeaderAccordionContent>
						<Divider />
						{/* Family overview link */}
						<NextLink passHref href={`/${familySlug}`}>
							<ChakraLink
								textDecoration="none"
								fontWeight={600}
								paddingLeft={8}
								id={id}
							>{`${t("headerSubMenu.overview")} ${
								name
							}`}</ChakraLink>
						</NextLink>
						<Divider />
						<ProductHeaderItem
							familySlug={familySlug}
							products={products}
							isMobile
						/>
					</HeaderAccordionContent>
				</HeaderAccordion>
			))
			.flatMap(addDividerToHeaderMenuItems(<Divider />));
	}

	return (
		<chakra.div display="flex" flexDir="column" flexWrap="wrap">
			<chakra.div display="flex">
				{familyHeaderItems.map(
					({
						family: {
							id,
							slug,
							name,
							color: { hex: familyColor },
						},
						products,
					}) => {
						return (
							<chakra.div key={id} padding="4">
								<HeaderDropdownItem
									href={`${slug}`}
									label={name}
									icon={<ArrowRight sx={styles.arrow} />}
									sx={styles.family}
									backgroundColor={`${hexColorWithOpacity(familyColor, 0.15)}`}
								/>
								<ProductHeaderItem
									familySlug={slug}
									products={products}
								/>
							</chakra.div>
						);
					},
				)}
			</chakra.div>

			{headerItems.map(({ page }) => (
				<HeaderDropdownItem
					key={page.name}
					href={`${page.slug}`}
					label={replacePipeWithShy(page.name)}
					icon={<ArrowRight sx={styles.arrow} />}
				/>
			))}
		</chakra.div>
	);
};

export const HeaderSubMenuNew: React.FC<HeaderSubmenuProps> = ({
	label,
	items,
	id,
}) => {
	return (
		<>
			<HeaderAccordion
				display={{
					base: "block",
					desktopNavigation: "none",
				}}
			>
				<ChakraHeaderAccordionButton id={id}>
					{label}
				</ChakraHeaderAccordionButton>
				<HeaderAccordionContent
					sx={{
						"hr + hr": {
							display: "none",
						},
					}}
				>
					<HeaderDropdownItems items={items} isMobile />
				</HeaderAccordionContent>
			</HeaderAccordion>

			<HeaderDropdown>
				<HeaderDropdownButton
					display={{
						base: "none",
						desktopNavigation: "block",
					}}
					id={id}
					variant="headerDropdown"
				>
					{label}
					<ChevronUp />
				</HeaderDropdownButton>

				<HeaderDropdownContent
					display={{
						base: "none",
						desktopNavigation: "block",
					}}
				>
					<MenuArrow />
					<HeaderDropdownItems items={items} />
				</HeaderDropdownContent>
			</HeaderDropdown>
		</>
	);
};

// 🔬 TBD: Please evaluate
