import {
	chakra,
	ChakraProps,
	Divider,
	Link as ChakraLink,
	useMultiStyleConfig,
	VStack,
} from "@chakra-ui/react";
import React from "react";
import { useGetInsuranceProductsByFamilyIdQuery } from "src/__generated__/client.codegen";
import { NextLink } from "src/components/NextLink";
import { useTranslatedString } from "src/i18n/i18n";
import { ArrowRight } from "src/icons";
import { usePortalType, useRole, useSiteSettings } from "src/lib/hooks";
import { isHiddenProduct } from "src/lib/productAndTGFilters";
import { replacePipeWithShy } from "src/lib/replaceStringWithReactNode";
import { hexColorWithOpacity, truthy } from "src/lib/utils";
import { useLoginInfo } from "src/queries/emil/account";
import { z } from "zod";
import { HeaderDropdownFragment } from "../../__generated__/cms-schema.codegen";
import { addDividerToHeaderMenuItems } from "../Header/CMSMenuItems";
import {
	HeaderAccordion,
	HeaderAccordionButton as ChakraHeaderAccordionButton,
	HeaderAccordionContent,
} from "../HeaderAccordion/HeaderAccordion";
import { HeaderDropdownItem } from "../HeaderDropdown/HeaderDropdown";
import { createProductIdAndHref } from "../ProductGroupPageContent/ProductGroupPageContent";
import { getFormattedProducts } from "../ProductOverview/utils";

export type Family = Extract<
	HeaderDropdownFragment["dropdownItems"][number],
	{ __typename: "InsuranceProductFamilyRecord" }
>;

const HeaderAccordionButton: React.FC<{ name: string } & ChakraProps> = ({
	name,
	...rest
}) => {
	const styles = useMultiStyleConfig("HeaderDropdown");

	return (
		<ChakraHeaderAccordionButton
			sx={styles.accordionButton}
			style={{
				background: "none",
				color: "white",
				textDecoration: "none",
			}}
			{...rest}
		>
			{replacePipeWithShy(name)}
		</ChakraHeaderAccordionButton>
	);
};

export const FamilySubMenu: React.FC<{
	family: Family;
	isMobile?: boolean;
}> = ({ family, isMobile }) => {
	const styles = useMultiStyleConfig("HeaderDropdown", {
		backgroundColor: `${hexColorWithOpacity(family.color.hex, 0.15)}`,
	});
	const loginInfo = useLoginInfo();
	const rolePermission = useRole();
	const portal = usePortalType();
	const t = useTranslatedString();

	const { locale } = useSiteSettings();
	const { data } = useGetInsuranceProductsByFamilyIdQuery({
		locale,
		familyId: family.id,
	});

	if (!data?.allInsuranceProducts.length) {
		return null;
	}

	const { singleProducts, cheapestProducts } = getFormattedProducts(
		data.allInsuranceProducts,
	);

	const products = [...singleProducts, ...cheapestProducts].filter(
		({ slug, showMenu }) =>
			!isHiddenProduct(slug, loginInfo, rolePermission, portal) &&
			Boolean(showMenu),
	);

	if (isMobile) {
		return (
			<HeaderAccordion>
				{/* Family accordion button */}
				<HeaderAccordionButton
					textTransform="uppercase"
					name={family.name}
				/>
				<HeaderAccordionContent>
					<Divider />
					{/* Family overview link */}
					<NextLink passHref href={`/${family.slug}`}>
						<ChakraLink
							textDecoration="none"
							fontWeight={600}
							paddingLeft={8}
							id={family.id}
						>{`${t("headerSubMenu.overview")} ${
							family.name
						}`}</ChakraLink>
					</NextLink>
					<Divider />
					{products
						.map((product) => {
							// the product has multiple target groups, make the product an accordion button and map the target groups beneath
							if (product.targetGroups.length > 1) {
								const { href } = createProductIdAndHref(
									product.slug,
									family.slug,
								);

								return (
									<HeaderAccordion key={product.id}>
										{/* Product accordion button */}
										<HeaderAccordionButton
											name={product.name}
										/>
										<HeaderAccordionContent>
											<Divider />
											{/* Product overview link */}
											<NextLink
												passHref
												href={`/${href}`}
											>
												<ChakraLink
													id={product.id}
													textDecoration="none"
													fontWeight={600}
													paddingLeft={24}
												>{`${t(
													"headerSubMenu.overview",
												)} ${replacePipeWithShy(
													product.name,
												)}`}</ChakraLink>
											</NextLink>
											<Divider />
											{product.targetGroups
												.filter(truthy)
												.map(({ id, name, slug }) => {
													const { href } =
														createProductIdAndHref(
															product.slug,
															family.slug,
															slug,
														);

													// target group links
													return (
														<NextLink
															key={id}
															passHref
															href={`/${href}`}
														>
															<ChakraLink
																textDecoration="none"
																fontWeight={500}
																paddingLeft={24}
																id={id}
															>
																{name}
															</ChakraLink>
														</NextLink>
													);
												})
												.flatMap(
													addDividerToHeaderMenuItems(
														<Divider />,
													),
												)}
										</HeaderAccordionContent>
									</HeaderAccordion>
								);
							}

							// if the product has no or only one target group, link it
							const { id, href } = createProductIdAndHref(
								product.slug,
								family.slug,
								product.targetGroup?.slug,
							);

							const externalLink = z
								.string()
								.url()
								.min(1)
								.safeParse(product.currentVersion.externalLink);

							return (
								<chakra.div key={id}>
									{externalLink.success ? (
										<ChakraLink
											id={product.id}
											textDecoration="none"
											fontWeight={600}
											paddingLeft={8}
											href={externalLink.data}
											isExternal
										>
											{replacePipeWithShy(product.name)}
										</ChakraLink>
									) : (
										<NextLink passHref href={`/${href}`}>
											<ChakraLink
												id={id}
												textDecoration="none"
												fontWeight={600}
												paddingLeft={8}
											>
												{replacePipeWithShy(
													product.name,
												)}
											</ChakraLink>
										</NextLink>
									)}
								</chakra.div>
							);
						})
						.flatMap(addDividerToHeaderMenuItems(<Divider />))}
				</HeaderAccordionContent>
			</HeaderAccordion>
		);
	}

	return (
		<VStack spacing={4} sx={styles.wrapper}>
			{/* Family */}
			<HeaderDropdownItem
				href={family.slug}
				label={family.name}
				icon={<ArrowRight sx={styles.arrow} />}
				sx={styles.family}
			/>
			{/* Products */}
			{products.map((product) => {
				// if product has more than one target group, link the product & its target groups
				if (product.targetGroups.length > 1) {
					const { id: productId, href } = createProductIdAndHref(
						product.slug,
						family.slug,
					);

					return (
						<chakra.div key={productId}>
							<HeaderDropdownItem
								href={href}
								label={replacePipeWithShy(product.name)}
								icon={<ArrowRight sx={styles.arrow} />}
								fontWeight="bold"
								fontSize="md"
							/>
							{product.targetGroups
								.filter(truthy)
								.map((targetGroup) => {
									const { id: tgId, href } =
										createProductIdAndHref(
											product.slug,
											family.slug,
											targetGroup.slug,
										);

									// target group link
									return (
										<HeaderDropdownItem
											key={tgId}
											href={href}
											label={targetGroup.name}
											icon={
												<ArrowRight sx={styles.arrow} />
											}
											fontSize="sm"
											fontWeight={500}
										/>
									);
								})}
						</chakra.div>
					);
				}

				// products with no or one target group
				const { id, href } = createProductIdAndHref(
					product.slug,
					family.slug,
					product.targetGroup?.slug,
				);

				const externalLink = z
					.string()
					.url()
					.min(1)
					.safeParse(product.currentVersion.externalLink);

				return (
					<HeaderDropdownItem
						key={id}
						href={externalLink.success ? externalLink.data : href}
						label={replacePipeWithShy(product.name)}
						icon={<ArrowRight sx={styles.arrow} />}
						fontWeight="bold"
						fontSize="md"
						isExternal={externalLink.success}
					/>
				);
			})}
		</VStack>
	);
};

// 🔬 TBD: Please evaluate
